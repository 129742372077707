import { addMessageListener, receiveMessages, sendMessage } from './utils/message'

export class CustomPostMessage {
  initialized = false
  constructor(
    private iframe: () => HTMLIFrameElement | null,
  ) { }

  initializedCallbackQueue: (() => void)[] = []

  protected init() {
    addMessageListener('initialized', async () => {
      this.initialized = true
      this.placement()
      await Promise.all(this.initializedCallbackQueue.map(fn => fn()))
      this.initializedCallbackQueue = []
    })
  }

  postMessage(data: Parameters<typeof sendMessage>[1]) {
    const iframe = this.iframe()
    if (!iframe)
      throw new Error('Please login with your custom wallet first')

    if (!this.initialized) {
      this.initializedCallbackQueue.push(() => this.postMessage(data))
      return
    }
    sendMessage(iframe, data)
  }

  receiveMessage = receiveMessages
  addMessageListener = addMessageListener

  viewWallet() {
    this.push('/view')
  }

  push(path: string) {
    this.postMessage({
      type: 'router',
      path,
    })
  }

  replace(path: string, params?: Record<string, any>) {
    this.postMessage({
      type: 'router',
      path,
      replace: true,
      ...params,
    })
  }

  checkWalletAddress(address: string) {
    this.postMessage({
      type: 'checkWalletAddress',
      address,
    })
  }

  placement() {
    this.postMessage({
      type: 'where',
      placement: 'walletLogin',
    })
  }

  logout() {
    this.postMessage({
      type: 'logout',
    })
  }

  setLocale(locale: string) {
    this.postMessage({
      type: 'locale',
      locale,
    })
  }
}
