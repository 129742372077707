export interface EventMessage {
  // modal 状态
  modal: {
    state: boolean
  }
  // 取消登录
  cancelLogin: void
  lifecycle: {
    name: 'login' | 'loginFailed' | 'createWallet' | 'reconstruct' | 'viewAccount' | 'reconstructFailed'
  }
  loginFailed: {
    err?: Error
    errorMessage: string
  }
  // wallet 已完成初始化
  initialized: void
  connectWalletByWalletName: {
    name: string
    walletAddress: string
  }
  // 登出
  401: void
  wcUri: {
    code: string
  }
  logout: void
  $all: any
  signingMessage: {
    sid: string
    message: string
    name: string
  }
}

export type EventMessageCallback = ((type: keyof EventMessage, data: any, event: MessageEvent) => void)

export interface ListenerCallback<T extends keyof EventMessage> {
  (data: EventMessage[T]): any
}

const eventListener = new Map<keyof EventMessage, ((...args: any[]) => void)[]>()

export const addMessageListener = <T extends keyof EventMessage>(type: T, callback: ListenerCallback<T>) => {
  const listeners = eventListener.get(type) || []
  eventListener.set(type, [...listeners, callback])
}

export const removeMessageListener = <T extends keyof EventMessage>(type: T, callback: ListenerCallback<T>) => {
  const listeners = (eventListener.get(type) || []).filter(listener => listener !== callback)
  eventListener.set(type, listeners)
}

const eventListeners = new Set<EventMessageCallback>()

const executeListener = <T extends keyof EventMessage>(type: T, data: any, event: MessageEvent) => {
  const listeners = (eventListener.get(type) || [])
  listeners.forEach(listener => [
    listener(data, event),
  ])
  eventListeners.forEach((listener) => {
    return listener(type, data, event)
  })
}

export const receiveMessages = (callback?: EventMessageCallback) => {
  eventListeners.add(callback)
  return () => eventListeners.delete(callback)
}

window.addEventListener('message', (event: WindowEventMap['message']) => {
  if (event.data.type !== 'authcWallet')
    return
  const messageType = event.data.data.type
  Reflect.deleteProperty(event.data, 'type')
  executeListener(messageType, event.data.data, event)
})

export const sendMessage = (iframe: HTMLIFrameElement, data: {
  type: string
  [key: string]: any
}) => {
  iframe.contentWindow.postMessage({
    type: 'authcWallet',
    data,
  }, '*')
}
