{
    "name": "authc-spa-js",
    "version": "0.8.2",
    "packageManager": "pnpm@7.26.0",
    "description": "Authc SDK for Single Page Applications using Authorization Code Grant Flow with PKCE",
    "author": "Authc",
    "license": "MIT",
    "exports": {
        ".": {
            "types": "./dist/src/index.d.ts",
            "require": "./dist/src/index.js",
            "import": "./dist/src/index.mjs",
            "browser": "./dist/src/index.global.js"
        },
        "./wallet": {
            "types": "./dist/wallet.d.ts",
            "require": "./dist/wallet.js",
            "import": "./dist/wallet.mjs",
            "browser": "./dist/wallet.global.js"
        },
        "./authc": {
            "types": "./dist/authc.d.ts",
            "require": "./dist/authc.js",
            "import": "./dist/authc.mjs",
            "browser": "./dist/authc.global.js"
        },
        "./iframe": {
            "types": "./dist/iframe.d.ts",
            "require": "./dist/iframe.js",
            "import": "./dist/iframe.mjs",
            "browser": "./dist/iframe.global.js"
        }
    },
    "main": "dist/src/index.cjs",
    "module": "dist/src/index.mjs",
    "unpkg": "dist/src/index.js",
    "types": "dist/src/index.d.ts",
    "browser": "dist/src/index.global.js",
    "scripts": {
        "dev": "tsup --watch",
        "start": "npm run dev",
        "docs": "typedoc --options ./typedoc.js --module commonjs",
        "build:cjs": "tsc --project . --module commonjs --outDir dist/cjs",
        "build:esm": "tsc --project . --module esnext --outDir dist/esm",
        "build:iife": "tsup --minify",
        "build:tsc": "pnpm run build:cjs && pnpm run build:esm && pnpm run build:iife",
        "build": "tsup --clean",
        "build:stats": "npm run build -- --environment WITH_STATS:true && open stats.html",
        "lint": "eslint .",
        "lint:fix": "pnpm lint --fix",
        "test": "jest --coverage --silent",
        "test:watch": "jest --coverage --watch",
        "test:debug": "node --inspect node_modules/.bin/jest --runInBand",
        "test:open:integration": "cypress open",
        "test:watch:integration": "concurrently --raw npm:dev 'npm:test:open:integration'",
        "test:es-check": "npm run test:es-check:es5 && npm run test:es-check:es2015:module",
        "test:es-check:es5": "es-check es5 'dist/authc-spa-js.production.js'",
        "test:es-check:es2015:module": "es-check es2015 'dist/authc-spa-js.production.esm.js' --module ",
        "test:integration:server": "npm run dev",
        "test:integration:tests": "wait-on http://localhost:3000/ && cypress run",
        "test:integration": "concurrently --raw --kill-others --success first npm:test:integration:server npm:test:integration:tests",
        "serve:coverage": "serve coverage/lcov-report -n",
        "serve:stats": "serve bundle-stats -n",
        "print-bundle-size": "node ./scripts/print-bundle-size",
        "prepublishOnly": "pnpm run build",
        "release": "pnpm changeset && pnpm changeset version && pnpm i --lockfile-only && git add . && git commit -m 'chore: release' && pnpm changeset tag && git push --follow-tags"
    },
    "dependencies": {
        "@coinbase/wallet-sdk": "^3.6.5",
        "@tkey/chrome-storage": "^7.0.0",
        "@tkey/common-types": "^7.0.0",
        "@tkey/core": "^7.0.0",
        "@tkey/default": "^7.0.0",
        "@tkey/private-keys": "^7.0.0",
        "@tkey/security-questions": "^7.0.0",
        "@tkey/seed-phrase": "^7.0.0",
        "@tkey/service-provider-base": "^7.0.0",
        "@tkey/service-provider-torus": "^7.0.0",
        "@tkey/share-serialization": "^7.0.0",
        "@tkey/share-transfer": "^7.0.0",
        "@tkey/storage-layer-torus": "^7.0.0",
        "@tkey/web-storage": "^7.0.0",
        "@types/node": "^18.11.9",
        "@wagmi/chains": "^1.5.0",
        "@wagmi/connectors": "^2.6.6",
        "@wagmi/core": "^1.3.7",
        "@walletconnect/sign-client": "^2.9.0",
        "@walletconnect/universal-provider": "^2.9.0",
        "@web3modal/core": "2.6.0",
        "@web3modal/ethereum": "2.6.0",
        "@web3modal/ui": "2.6.0",
        "abortcontroller-polyfill": "^1.7.5",
        "axios": "^0.26.1",
        "bowser": "^2.11.0",
        "browser-tabs-lock": "^1.2.15",
        "bumpp": "^8.2.1",
        "core-js": "^3.20.3",
        "es-cookie": "^1.3.2",
        "fast-text-encoding": "^1.0.3",
        "promise-polyfill": "^8.2.3",
        "unbuild": "^1.0.2",
        "unfetch": "^4.2.0",
        "viem": "^1.2.12",
        "zustand": "^4.4.1"
    },
    "devDependencies": {
        "@antfu/eslint-config": "^0.35.1",
        "@changesets/cli": "^2.26.0",
        "@esbuild-plugins/node-globals-polyfill": "^0.1.1",
        "@esbuild-plugins/node-modules-polyfill": "^0.1.4",
        "@rollup/plugin-replace": "^2.4.2",
        "@toruslabs/customauth": "^11.0.1",
        "@types/cypress": "^1.1.3",
        "@types/jest": "^27.0.2",
        "@walletconnect/ethereum-provider": "^2.9.0",
        "browserstack-cypress-cli": "1.8.1",
        "cli-table": "^0.3.6",
        "codecov": "^3.8.3",
        "concurrently": "^6.4.0",
        "cypress": "7.2.0",
        "es-check": "^6.1.1",
        "esbuild": "^0.18.10",
        "eslint": "^8.33.0",
        "gzip-size": "^6.0.0",
        "husky": "^7.0.4",
        "idtoken-verifier": "^2.2.2",
        "jest": "^27.3.1",
        "jest-junit": "^13.0.0",
        "jest-localstorage-mock": "^2.4.18",
        "jsonwebtoken": "^8.5.1",
        "oidc-provider": "^7.10.1",
        "pem": "^1.14.4",
        "prettier": "^2.4.1",
        "pretty-quick": "^3.1.2",
        "qss": "^2.0.3",
        "rimraf": "^3.0.2",
        "rollup": "^2.60.0",
        "rollup-plugin-analyzer": "^4.0.0",
        "rollup-plugin-commonjs": "^10.1.0",
        "rollup-plugin-dev": "^1.1.3",
        "rollup-plugin-dts": "^5.1.1",
        "rollup-plugin-esbuild": "^5.0.0",
        "rollup-plugin-lit-css": "^4.0.1",
        "rollup-plugin-livereload": "^2.0.5",
        "rollup-plugin-minify-html-literals": "^1.2.6",
        "rollup-plugin-node-resolve": "^5.2.0",
        "rollup-plugin-sourcemaps": "^0.6.3",
        "rollup-plugin-terser": "^7.0.2",
        "rollup-plugin-typescript2": "^0.30.0",
        "rollup-plugin-visualizer": "^5.5.2",
        "rollup-plugin-web-worker-loader": "^1.6.1",
        "serve": "^12.0.1",
        "ts-jest": "^27.0.7",
        "tslib": "^2.3.1",
        "tslint": "^6.1.3",
        "tslint-config-security": "^1.16.0",
        "tsup": "^7.1.0",
        "typedoc": "0.18.0",
        "typescript": "^5.1.6",
        "wait-on": "^6.0.0"
    },
    "husky": {
        "hooks": {
            "pre-commit": "pretty-quick --staged"
        }
    }
}
