import type { WalletData } from '@web3modal/core'
import { CoreUtil } from '@web3modal/core'
import { version } from '../../../package.json'

export function formatUniversalUrl(appUrl: string, wcUri: string, name: string): string {
  if (!CoreUtil.isHttpUrl(appUrl))
    return formatNativeUrl(appUrl, wcUri, name)

  let plainAppUrl = appUrl
  if (appUrl.endsWith('/'))
    plainAppUrl = appUrl.slice(0, -1)

  const encodedWcUrl = encodeURIComponent(wcUri)

  const search = new URLSearchParams({
    uri: encodedWcUrl,
    automatic: '1',
  })
  return `${plainAppUrl}/wc?${search.toString()}`
}

function formatNativeUrl(appUrl: string, wcUri: string, name: string): string {
  if (CoreUtil.isHttpUrl(appUrl))
    return formatUniversalUrl(appUrl, wcUri, name)

  const plainAppUrl = appUrl.replaceAll('/', '').replaceAll(':', '')
  const encodedWcUrl = encodeURIComponent(wcUri)

  return `${plainAppUrl}://wc?uri=${encodedWcUrl}`
}

export function openHref(href: string, target = '_self') {
  const a = document.createElement('a')
  a.target = target
  a.href = href
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export const handleMobileLinking = async (uri: string, wallet: WalletData, forceUniversalUrl = false) => {
  const { mobile, name } = wallet
  const nativeUrl = mobile?.native
  const universalUrl = mobile?.universal

  if (nativeUrl && !forceUniversalUrl) {
    const href = CoreUtil.formatNativeUrl(nativeUrl, uri, name)
    CoreUtil.openHref(href, '_self')
  }
  else if (universalUrl) {
    const href = CoreUtil.formatUniversalUrl(universalUrl, uri, name)
    CoreUtil.openHref(href, '_self')
  }
}

export const handleDesktopLinking = async (uri: string, wallet: WalletData, universal = true) => {
  const { desktop, name } = wallet

  if (universal && desktop.universal) {
    const href = CoreUtil.formatUniversalUrl(desktop.universal, uri, name)
    CoreUtil.openHref(href, '_blank')
  }
  else if (desktop.native) {
    const href = CoreUtil.formatNativeUrl(desktop.native, uri, name)
    CoreUtil.openHref(href, '_self')
  }
}

export const createWalletLoginIframe = (src: string, props: Partial<Pick<HTMLIFrameElement, 'onerror' | 'onload'>> = {}) => {
  const iframe = document.createElement('iframe')
  iframe.allow = 'clipboard-write *;camera *'
  iframe.style.position = 'fixed'
  iframe.style.border = '0'
  iframe.style.inset = '0'
  iframe.style.width = '100%'
  iframe.style.height = '100%'
  iframe.style.zIndex = '-1'
  iframe.style.pointerEvents = 'none'
  iframe.dataset.authcWallet = ''
  iframe.src = src + `&SDKVersion=${version}`

  Object.keys(props).forEach((key: 'onload') => {
    iframe[key] = props[key]
  })

  window.document.body.appendChild(iframe)

  return iframe
}

export const createLogoutIframe = (url: string) => {
  const iframe = document.createElement('iframe')
  iframe.style.position = 'fixed'
  iframe.style.zIndex = '-1'
  iframe.style.left = '-10000px'
  iframe.style.top = '-10000px'
  iframe.style.pointerEvents = 'none'
  iframe.src = url

  return new Promise<void>((resolve, reject) => {
    window.document.body.appendChild(iframe)
    iframe.onload = () => {
      resolve()
      iframe.remove()
    }
    iframe.onerror = () => {
      reject()
    }
  })
}
